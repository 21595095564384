import { h, Component } from 'preact';
import style from './style';
import { Modal, Button } from "react-bootstrap";
import { withTranslation, Trans } from "react-i18next";
import { AppConstant } from '../../constatnt/AppConstant';

class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let self = this;
        const {t}=self.props
        let header = self.props.header ? self.props.header : "Alert";
        let buttonOk = "";
        let buttonCancel = "";
        
        if (self.props.onSubmit){
            buttonOk = <button type="button" class="btn btn-primary mx-auto btn-lg" onClick={self.props.onSubmit}>{t(AppConstant.OK)}</button>;
            buttonCancel = <button type="button" class="btn btn-primary mx-auto btn-lg" onClick={self.props.onClose}>{t(AppConstant.CANCEL)}</button>;
        }
        else{
            buttonOk = <button type="button" class="btn btn-primary mx-auto btn-lg" onClick={self.props.onClose}>{t(AppConstant.OK)}</button>;
        }

        let modal = 
            <Modal show={self.props.open} onHide={self.props.onClose}>
                    <Modal.Header>
                        <h5 class="modal-title" id="exampleModalLabel">{header}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <p class="lead">{self.props.children}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        {buttonOk}
                        {buttonCancel}
                    </Modal.Footer>
            </Modal>;

        return (
            <div>
                {modal}
            </div>
        );
    }
}

export default withTranslation()(AlertModal);