import Configuration from './configuration';
const axios = require('axios').default;

export default class GlobalAccess {
  static getDevices = (app, callback) => {
    axios.get(Configuration.mdashURL + '/customer?access_token=' + localStorage.ktok).then(function (res) {
      let devices = {},
        pubkeys = res.data.pubkeys || {},
        isDemo = res.data.cconfig?.isDemo || 0,
        untappd_token = res.data.cconfig?.untappd_token || "",
        bizDayStart = res.data.cconfig?.bizDayStart || "00:00";

      let keys = Object.keys(pubkeys);
      let pending = keys.map(function (k) {
        let url = Configuration.mdashURL + '/api/v2/m/device?access_token=' + encodeURIComponent(k);
        return axios({
          method: 'GET',
          url: url,
        })
          .then(function (res) {
            devices[k] = res.data;
          })
          .catch(function () {});
      });
      Promise.all(pending).then(function () {
        app.setState({
          devices: devices,
          pubkeys: pubkeys,
          isDemo: isDemo,
          untappd_token: untappd_token,
          bizDayStart: bizDayStart
        }, () => {
          if (callback) callback(untappd_token);
        });
      });
    });
  };

  static getAllServings = (app, startDate, endDate) => {
    app.setState({ isServingsLoading: true });
    axios.get(Configuration.mdashURL + '/customer?access_token=' + localStorage.ktok).then(function (res) {
      let servings = {},
        pubkeys = res.data.pubkeys || {};

      let keys = Object.keys(pubkeys);
      let pending = keys.map(function (k) {
        let url = Configuration.mdashURL + '/api/v2/m/device/data/servings-device?access_token=' + encodeURIComponent(k);

        let data = {
          params: [startDate, endDate],
        };

        return axios({
          method: 'POST',
          url,
          data,
        })
          .then((res) => {
            servings[k] = res.data;
          })
          .catch(() => {});
      });
      return Promise.all(pending).then(() => {
        app.setState({
          servings,
          pubkeys,
        });
      });
    })
    .catch(() => {
      app.setState({ isServingsLoading: false });
    })
    .finally(() => {
      app.setState({ isServingsLoading: false });
    });
  };

  static getAllServingsOld = (app, startDate, endDate) => {
    axios.get(Configuration.mdashURL + '/customer?access_token=' + localStorage.ktok).then(function (res) {
      let servings = {},
        pubkeys = res.data.pubkeys || {};

      let keys = Object.keys(pubkeys);
      let pending = keys.map(function (k) {
        let url = Configuration.mdashURL + '/api/v2/m/device/data/servings-all?access_token=' + encodeURIComponent(k);
        let data = {
          params: [startDate, endDate],
        };

        return axios({
          method: 'POST',
          url: url,
          data: data,
        })
          .then((res) => {
            servings[k] = res.data;
          })
          .catch(() => {});
      });
      Promise.all(pending).then(() => {
        app.setState({
          servings: servings,
          pubkeys,
        });
      });
    });
  };
}
