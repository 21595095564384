import { h, Component } from 'preact';
import AlertModal from "../alert-modal";

export default class SpinButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spin: false,
      alertOpen: false,
      errorMsg: ""
    };
  }

  closeAlert = () => {
    let self = this;
    self.setState({
      alertOpen: false,
      errorMsg: ""
    });
  };

  onButtonClick = async (ev, args) => {
    let self = this;

    if (!self.props.onClick) return;

    self.setState({
      spin: true,
    });

    try {
      await self.props.onClick.apply(null, args);
    } catch(e) {
      let errorMessage = ((e.response || {}).data || {}).error || {};
      let msg = errorMessage.message || e.message || e;
      self.setState({errorMsg: msg, alertOpen: true});
    } finally {
      self.setState({
         spin: false,
      });
    }
  };

  render() {
    let self = this;

    return (
      <>
        <button class={'btn ' + (self.props.class || self.props.className || '')} disabled={self.props.disabled || self.state.spin} style={self.props.style || ''} ref={self.props.ref} onClick={self.onButtonClick}>
          {self.props.svg ? (
            <span class="svg-icon mr-1">
              {self.props.svg}
            </span>
          ) : (
            <i
              class={'mr-1 fa fa-fw ' + (self.state.spin ? 'fa-refresh' : self.props.icon || 'fa-save') + (self.state.spin ? ' fa-spin' : '')}
            ></i>
          )}
          {self.props.title || 'submit'}
        </button>
        <AlertModal
          open={self.state.alertOpen}
          onClose={self.closeAlert}
        >
          <center><span>
            {self.state.errorMsg}
          </span></center>  
        </AlertModal>
      </>
    );
  }
}
